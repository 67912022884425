import {
    AnalyticsCategory,
    AnalyticsGroupManagementAction,
} from '@analytics-lib/analytics.model'
import { AnalyticsService } from '@analytics-lib/analytics.service'
import { Component, Injector, OnDestroy, OnInit } from '@angular/core'
import {
    UntypedFormBuilder,
    UntypedFormControl,
    Validators,
} from '@angular/forms'
import { AuthzService } from '@auth-util-lib/authz.service'
import { GroupManagementQuitDialogComponent } from '@group-management-lib/components/group-management-quit-dialog/group-management-quit-dialog.component'
import {
    ParsedAccessPeriod,
    UserSelection,
    Wagon,
    WagonId,
} from '@group-management-lib/group-management.model'
import { GroupManagementService } from '@group-management-lib/group-management.service'
import {
    deleteGroup,
    editGroup,
    loadGroupsWithResources,
    loadPossibleWagonsForUser,
} from '@group-management-lib/redux/group-management.actions'
import {
    selectAccessPeriodOfSelectedGroup,
    selectChangeSelectedWagonsForGroupError,
    selectChangeSelectedWagonsForGroupLoading,
    selectColleaguesOfSelectedGroup,
    selectEditGroupError,
    selectEditGroupLoading,
    selectGroupIdToEdit,
    selectGroupToEdit,
    selectWagonsForSelectedGroup,
    selectWagonsForSelectedGroupLoading,
} from '@group-management-lib/redux/group-management.selectors'
import { GroupManagementState } from '@group-management-lib/redux/group-management.state'
import { accessPeriodsEqual } from '@group-management-lib/util/accessPeriodComparison'
import { resetSteps } from '@group-management-lib/util/stepperControls'
import { validateGroupName } from '@group-management-lib/validators/validateGroupName'
import dayjs from '@localization-lib/date-time/dayjs'
import { defaultDate } from '@localization-lib/date-time/dayjs/dayjsHelper'
import { Store, select } from '@ngrx/store'
import { DialogService } from '@shared-ui-lib/dialog/dialog.service'
import { OverlayAnimatedContent } from '@shared-ui-lib/overlay/overlay-animated-content'
import { Animations } from '@shared-util-lib/animations/animations'
import { compareArrays } from '@util-lib/compareArrays'
import { isTruthy } from '@util-lib/isTruthy'
import {
    BehaviorSubject,
    Observable,
    ReplaySubject,
    Subject,
    Subscription,
    combineLatest,
    distinctUntilChanged,
    startWith,
    withLatestFrom,
} from 'rxjs'
import { filter, map } from 'rxjs/operators'

@Component({
    selector: 'app-group-management-editor',
    templateUrl: './group-management-editor.component.html',
    styleUrls: ['./group-management-editor.component.scss'],
    animations: [Animations.slideContentRight],
})
export class GroupManagementEditorComponent
    extends OverlayAnimatedContent
    implements OnInit, OnDestroy
{
    // delegate shared functions
    protected readonly resetSteps = resetSteps

    subscriptions = new Subscription()

    groupId: string | null = null
    groupName: string | null = null
    isDefaultGroup: boolean | null = null
    isSynchronizedGroup = false

    /*
      have to inject Service this way, otherwise would lead to
      "uncaught ReferenceError: Cannot access "GroupManagementService" before initialization"
      alternative: set "emitDecoratorMetadata" to false in tsconfig.base.ts
  */
    private groupManagementService = this.inj.get(GroupManagementService)

    groupNameControl = new UntypedFormControl('', [
        Validators.required,
        validateGroupName(),
    ])

    // TODO combine with other controls
    groupNameFormGroup = this.formBuilder.group({
        groupName: this.groupNameControl,
    })

    private readonly saveTriggered$ = new Subject()

    initialGroupName$ = new BehaviorSubject<string | null>(null)
    initialSelectedWagons$ = new BehaviorSubject<Wagon[]>([])

    isGroupAccessible$ = new BehaviorSubject<boolean>(false)
    isGroupEmptyAndNormalUserInGacClient$ = new BehaviorSubject<boolean>(false)

    readonly colleagues$: Observable<UserSelection[]> = this.store.pipe(
        select(selectColleaguesOfSelectedGroup)
    )
    readonly userSelectionChanged = new BehaviorSubject<boolean>(false)
    readonly selectedUserIds = new ReplaySubject<string[]>(1)

    readonly currentAccessPeriod$: Observable<ParsedAccessPeriod> = this.store
        .pipe(select(selectAccessPeriodOfSelectedGroup))
        .pipe(filter(isTruthy))
    selectedAccessPeriod$ = new ReplaySubject<ParsedAccessPeriod>(1)
    readonly accessPeriodChanged = new BehaviorSubject<boolean>(false)

    selectedWagons$ = new BehaviorSubject<Wagon[]>([])
    containsInvalidWagonNumberEntries$ = new BehaviorSubject<boolean>(true)

    loadPossibleWagonsLoading$ = this.store.pipe(
        select(selectWagonsForSelectedGroupLoading)
    )

    editGroupLoading$ = this.store.pipe(select(selectEditGroupLoading))
    editGroupError$ = this.store.pipe(select(selectEditGroupError))

    changeWagonSelectionLoading$ = this.store.pipe(
        select(selectChangeSelectedWagonsForGroupLoading)
    )
    changeWagonSelectionError$ = this.store.pipe(
        select(selectChangeSelectedWagonsForGroupError)
    )

    savingErrorOccurred$ = new BehaviorSubject<boolean>(false)

    readonly isAdmin$ = this.groupManagementService.isAdmin$

    readonly isGroupManagementAllowed$ =
        this.groupManagementService.isGroupManagementAllowed$

    readonly isExtendedGroupManagementAllowed$ =
        this.groupManagementService.isAdminInGacClient$

    readonly accessPeriodBeginOn$: Observable<string> =
        this.currentAccessPeriod$.pipe(
            map((accessPeriod) => {
                if (accessPeriod.beginOn) {
                    return defaultDate(accessPeriod.beginOn).format(
                        'DD.MM.YYYY'
                    )
                } else {
                    return ''
                }
            })
        )

    readonly accessPeriodEndOn$: Observable<string | null> =
        this.currentAccessPeriod$.pipe(
            map((accessPeriod) => {
                if (accessPeriod.endOn) {
                    return defaultDate(accessPeriod.endOn).format('DD.MM.YYYY')
                } else {
                    return null
                }
            })
        )

    // show stepper only if it has a second page, i.e. if group members can be managed
    readonly showStepper$ = this.isExtendedGroupManagementAllowed$.pipe(
        distinctUntilChanged()
    )
    readonly stepIndex = new BehaviorSubject(0)

    readonly hasNextStep$ = combineLatest([
        this.stepIndex,
        this.showStepper$,
    ]).pipe(map(([index, showStepper]) => showStepper && index === 0))

    readonly hasPreviousStep$ = combineLatest([
        this.stepIndex,
        this.showStepper$,
    ]).pipe(map(([index, showStepper]) => showStepper && index === 1))

    readonly shouldHaveSaveButton$ = this.hasNextStep$.pipe(
        // when editing a default group, there is nothing to save in the first step
        map((hasNextStep) => !(hasNextStep && this.isDefaultGroup))
    )

    constructor(
        private inj: Injector,
        private formBuilder: UntypedFormBuilder,
        private analyticsService: AnalyticsService,
        private dialogService: DialogService,
        private store: Store<GroupManagementState>,
        private authzService: AuthzService
    ) {
        super()
        this.subscriptions.add(
            store.pipe(select(selectGroupToEdit)).subscribe((group) => {
                const groupName = group?.name || null
                this.groupName = groupName
                this.initialGroupName$.next(groupName)
                this.groupNameControl.setValue(groupName)
                this.isDefaultGroup =
                    group?.isDefault !== undefined ? group?.isDefault : null
                this.isSynchronizedGroup = group?.isSynchronizedGroup ?? false
            })
        )

        this.subscriptions.add(
            store
                .pipe(select(selectGroupIdToEdit))
                .subscribe((groupId) => (this.groupId = groupId))
        )

        this.subscriptions.add(
            combineLatest([
                this.editGroupError$,
                this.changeWagonSelectionError$,
            ]).subscribe(([editGroupError, changeWagonSelectionError]) => {
                this.savingErrorOccurred$.next(
                    editGroupError !== null ||
                        changeWagonSelectionError !== null
                )
            })
        )

        this.subscriptions.add(
            this.saveTriggered$
                .pipe(
                    withLatestFrom(
                        this.selectedUserIds.pipe(startWith([])),
                        this.selectedAccessPeriod$.pipe(
                            startWith({} as ParsedAccessPeriod)
                        ),
                        this.isExtendedGroupManagementAllowed$
                    )
                )
                .subscribe(
                    ([
                        _,
                        selectedUserIds,
                        selectedAccessPeriod,
                        isExtendedGroupManagementAllowed,
                    ]) => {
                        if (!this.groupId || this.isDefaultGroup === null)
                            return
                        const groupName =
                            this.groupNameFormGroup.controls.groupName.value

                        const selectedWagons = this.selectedWagons$.value.map(
                            (wagon) => ({ id: wagon.wagonId }) as WagonId
                        )

                        this.store.dispatch(
                            editGroup({
                                groupId: this.groupId,
                                groupName,
                                selectedWagons,
                                selectedUserIds,
                                selectedAccessPeriod,
                                isDefaultGroup: this.isDefaultGroup,
                                isExtendedGroupManagementAllowed,
                            })
                        )
                    }
                )
        )

        this.subscriptions.add(
            combineLatest([this.colleagues$, this.selectedUserIds])
                .pipe(
                    map(([members, selectedUserIds]) => {
                        const groupUserIds = members
                            .filter((user) => user.isMember)
                            .map((user) => user.userId)
                        return !compareArrays(groupUserIds, selectedUserIds)
                    }),
                    distinctUntilChanged()
                )
                .subscribe((changed) => {
                    this.userSelectionChanged.next(changed)
                })
        )

        this.subscriptions.add(
            combineLatest([
                this.currentAccessPeriod$,
                this.selectedAccessPeriod$,
            ])
                .pipe(
                    map(
                        ([initialAccessPeriod, selectedAccessPeriod]) =>
                            !accessPeriodsEqual(
                                initialAccessPeriod,
                                selectedAccessPeriod
                            )
                    ),
                    distinctUntilChanged()
                )
                .subscribe((changed) => {
                    this.accessPeriodChanged.next(changed)
                })
        )

        this.subscriptions.add(
            combineLatest([this.isAdmin$, this.isActiveGroup$])
                .pipe(
                    map(([isAdmin, isActiveGroup]) => isAdmin || isActiveGroup)
                )
                .subscribe((isGroupAccessible: boolean) => {
                    this.isGroupAccessible$.next(isGroupAccessible)
                })
        )

        this.subscriptions.add(
            combineLatest([
                this.isAdmin$,
                this.isEmptyGroup$,
                this.loadPossibleWagonsLoading$,
                this.authzService.isGroupAccessControlEnabled$,
            ])
                .pipe(
                    map(
                        ([
                            isAdmin,
                            isEmptyGroup,
                            loadPossibleWagonsLoading,
                            isGacEnabled,
                        ]) =>
                            !isAdmin &&
                            isEmptyGroup &&
                            !loadPossibleWagonsLoading &&
                            isGacEnabled
                    )
                )
                .subscribe((isGroupEmptyAndNormalUserInGacClient: boolean) => {
                    this.isGroupEmptyAndNormalUserInGacClient$.next(
                        isGroupEmptyAndNormalUserInGacClient
                    )
                })
        )
    }

    ngOnInit() {
        this.store.dispatch(loadGroupsWithResources())
        this.store.dispatch(loadPossibleWagonsForUser())

        this.subscriptions.add(
            this.store
                .pipe(select(selectWagonsForSelectedGroup))
                .subscribe((wagons) => {
                    this.initialSelectedWagons$.next(wagons ?? [])
                })
        )

        this.subscriptions.add(
            this.isGroupManagementAllowed$.subscribe((isAllowed) => {
                if (isAllowed) {
                    this.groupNameFormGroup.enable()
                } else {
                    this.groupNameFormGroup.disable()
                }
            })
        )
    }

    isEmptyGroup$: Observable<boolean> = this.initialSelectedWagons$.pipe(
        map((wagons) => wagons.length === 0),
        startWith(false)
    )

    isPendingGroup$: Observable<boolean> = this.currentAccessPeriod$.pipe(
        map(
            (accessPeriod) =>
                !!accessPeriod.beginOn && dayjs().isBefore(accessPeriod.beginOn)
        ),
        startWith(false)
    )

    isExpiredGroup$: Observable<boolean> = this.currentAccessPeriod$.pipe(
        map(
            (accessPeriod) =>
                !!accessPeriod.endOn && dayjs().isAfter(accessPeriod.endOn)
        ),
        startWith(false)
    )

    readonly isActiveGroup$: Observable<boolean> = combineLatest([
        this.isPendingGroup$,
        this.isExpiredGroup$,
    ]).pipe(map(([isPending, isExpired]) => !isPending && !isExpired))

    didGroupChange(): boolean {
        const groupNameEqual =
            this.initialGroupName$.value === this.groupNameControl.value

        let listOfWagonsEqual
        if (
            this.initialSelectedWagons$.value.length ===
            this.selectedWagons$.value.length
        ) {
            listOfWagonsEqual = compareArrays(
                this.initialSelectedWagons$.value.map((w) => w.wagonId),
                this.selectedWagons$.value.map((w) => w.wagonId)
            )
        } else {
            listOfWagonsEqual = false
        }
        return (
            !groupNameEqual ||
            !listOfWagonsEqual ||
            this.userSelectionChanged.value ||
            this.accessPeriodChanged.value
        )
    }

    disableEditButton() {
        return (
            !this.groupNameFormGroup.valid ||
            this.containsInvalidWagonNumberEntries$.value ||
            !this.didGroupChange()
        )
    }

    requestClose(onCloseCallback?: () => void) {
        this.analyticsService.trackEvent({
            category: AnalyticsCategory.GroupManagement,
            action: AnalyticsGroupManagementAction.EditClose,
        })
        if (
            !this.didGroupChange() ||
            this.savingErrorOccurred$.value ||
            !this.isGroupAccessible$.value ||
            this.isGroupEmptyAndNormalUserInGacClient$.value
        ) {
            this.groupManagementService.closeLayer()
            onCloseCallback?.()
        } else {
            this.dialogService
                .openDialog(GroupManagementQuitDialogComponent, {
                    title: 'Quit editing?',
                    body: 'Your changes will not be saved.',
                    quitButton: 'Quit',
                    continueButton: 'Continue editing',
                })
                .afterClosed()
                .subscribe((quit) => {
                    if (quit) {
                        this.analyticsService.trackEvent({
                            category: AnalyticsCategory.GroupManagement,
                            action: AnalyticsGroupManagementAction.DialogQuitEditing,
                        })
                        this.groupManagementService.closeLayer()
                        onCloseCallback?.()
                    } else {
                        this.analyticsService.trackEvent({
                            category: AnalyticsCategory.GroupManagement,
                            action: AnalyticsGroupManagementAction.DialogContinueEditing,
                        })
                    }
                })
        }
    }

    public async selectedWagons(selectedWagons: Wagon[]) {
        this.selectedWagons$.next(
            selectedWagons.map(
                (wagon) =>
                    ({
                        wagonId: wagon.wagonId,
                        uicClass: wagon.uicClass,
                        wagonNumber: wagon.wagonNumber,
                    }) as Wagon
            )
        )
    }

    public async containsInvalidWagonNumberEntries(
        containsInvalidWagonNumberEntries: boolean
    ) {
        this.containsInvalidWagonNumberEntries$.next(
            containsInvalidWagonNumberEntries
        )
    }

    public async selectAccessPeriod(selectedAccessPeriod: ParsedAccessPeriod) {
        this.selectedAccessPeriod$.next(selectedAccessPeriod)
    }

    handleFormSubmit() {
        this.analyticsService.trackEvent({
            category: AnalyticsCategory.GroupManagement,
            action: AnalyticsGroupManagementAction.EditorSaveGroup,
        })

        this.saveTriggered$.next(null)
    }

    deleteGroup() {
        if (!this.groupName) return

        this.analyticsService.trackEvent({
            category: AnalyticsCategory.GroupManagement,
            action: AnalyticsGroupManagementAction.EditorDeleteGroup,
        })

        this.dialogService
            .openDialog(GroupManagementQuitDialogComponent, {
                title: `Delete $$groupName$$ for you and your colleagues?`,
                body: 'GroupManagementDeleteDialog',
                quitButton: 'Delete for everyone',
                continueButton: 'Cancel',
                groupName: this.groupName,
            })
            .afterClosed()
            .subscribe((deleteConfirmed: boolean) => {
                if (!this.groupId) return

                if (deleteConfirmed) {
                    this.analyticsService.trackEvent({
                        category: AnalyticsCategory.GroupManagement,
                        action: AnalyticsGroupManagementAction.DialogDeleteGroup,
                    })
                    this.store.dispatch(
                        deleteGroup({
                            groupId: this.groupId,
                        })
                    )
                    this.groupManagementService.closeLayer()
                } else {
                    this.analyticsService.trackEvent({
                        category: AnalyticsCategory.GroupManagement,
                        action: AnalyticsGroupManagementAction.DialogCancelDelete,
                    })
                }
            })
    }

    ngOnDestroy() {
        this.subscriptions.unsubscribe()
    }
}
