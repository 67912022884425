import {
    AnalyticsCategory,
    AnalyticsGroupManagementAction,
} from '@analytics-lib/analytics.model'
import {
    Component,
    EventEmitter,
    Input,
    OnDestroy,
    OnInit,
    Output,
    ViewChild,
} from '@angular/core'
import {
    AbstractControl,
    FormBuilder,
    FormGroupDirective,
    NgForm,
    ValidationErrors,
    ValidatorFn,
} from '@angular/forms'
import { ShowOnDirtyErrorStateMatcher } from '@angular/material/core'
import { MatInput } from '@angular/material/input'
import {
    SelectedWagon,
    Wagon,
} from '@group-management-lib/group-management.model'
import { GroupManagementService } from '@group-management-lib/group-management.service'
import {
    selectPossibleWagonsForUser,
    selectPossibleWagonsForUserError,
    selectPossibleWagonsForUserLoading,
} from '@group-management-lib/redux/group-management.selectors'
import { GroupManagementState } from '@group-management-lib/redux/group-management.state'
import { formatUicClass } from '@group-management-lib/util/formatUicClass'
import { Store, select } from '@ngrx/store'
import { InlineMessageType } from '@shared-ui-lib/inline-message/inline-message.model'
import { Suggestion } from '@shared-util-lib/models/suggestion.interface'
import { UicWagonNumberPipe } from '@shared-util-lib/pipes/uic-wagon-number/uic-wagon-number.pipe'
import { isTruthy } from '@util-lib/isTruthy'
import { wagonNumberValidationRegex } from '@util-lib/validation/wagonNumberValidationRegex'
import {
    BehaviorSubject,
    ReplaySubject,
    Subscription,
    combineLatest,
    filter,
    startWith,
} from 'rxjs'

class ShowOnDirtyWhenFocusedErrorStateMatcher extends ShowOnDirtyErrorStateMatcher {
    isControlFocused = false

    isErrorState(
        control: AbstractControl | null,
        form: FormGroupDirective | NgForm | null
    ): boolean {
        return this.isControlFocused && super.isErrorState(control, form)
    }
}

@Component({
    selector: 'app-group-management-wagon-number-input',
    templateUrl: './group-management-wagon-number-input.component.html',
    styleUrls: ['./group-management-wagon-number-input.component.scss'],
})
export class GroupManagementWagonNumberInputComponent
    implements OnDestroy, OnInit
{
    AnalyticsCategory = AnalyticsCategory
    AnalyticsGroupManagementAction = AnalyticsGroupManagementAction
    inlineMessageType = InlineMessageType

    readonly separatorRegex: RegExp = /\r\n|\r|\n|,|;/
    readonly readOnly$ = new ReplaySubject(1)

    readonly isDefaultGroup$: ReplaySubject<boolean> = new ReplaySubject(1)
    readonly isAdmin$ = new ReplaySubject<boolean>(1)
    readonly accessPeriodEndOn$ = new ReplaySubject<string | null>(1)

    alreadySelectedWagonNumbers$ = new BehaviorSubject<Wagon[]>([])
    @Input()
    set alreadySelectedWagons(wagons: Wagon[]) {
        this.alreadySelectedWagonNumbers$.next(wagons)
    }

    @Input()
    set readOnly(readOnly: boolean) {
        this.readOnly$.next(readOnly)
    }

    @Input()
    set isDefaultGroup(isDefaultGroup: boolean | null) {
        this.isDefaultGroup$.next(isTruthy(isDefaultGroup))
    }

    @Input()
    set isAdmin(isAdmin: boolean) {
        this.isAdmin$.next(isAdmin)
    }

    @Input()
    set accessPeriodEndOn(endOn: string | null) {
        this.accessPeriodEndOn$.next(endOn)
    }

    isSynchronizedGroup$ = new BehaviorSubject<boolean>(false)
    @Input()
    set isSynchronizedGroup(value: boolean) {
        this.isSynchronizedGroup$.next(value)
    }

    @Output() public readonly selectedWagons = new EventEmitter<
        SelectedWagon[]
    >()

    @Output() public readonly containsInvalidWagonNumberEntries =
        new EventEmitter<boolean>()

    readonly wagonNumberInputControl = this.formBuilder.control<
        Suggestion<string> | string
    >('', [this.maxLengthValidator.bind(this), this.patternValidator()])

    readonly wagonNumberEditControl = this.formBuilder.control<string>('')

    // show messages directly before input field loses focus the first time
    readonly matcher = new ShowOnDirtyWhenFocusedErrorStateMatcher()

    @ViewChild(MatInput, { static: true }) searchMatInputInstance!: MatInput

    numberOfWagons = 0

    selectedWagons$ = new BehaviorSubject<SelectedWagon[]>([])
    invalidWagons$ = new BehaviorSubject<SelectedWagon[]>([])

    isWagonNumberInputValid$ = new BehaviorSubject<boolean>(true)

    possibleWagons$ = new BehaviorSubject<Wagon[]>([])
    possibleWagonsForUserError$ = this.store.pipe(
        select(selectPossibleWagonsForUserError)
    )
    possibleWagonsForUserLoading$ = new BehaviorSubject<boolean>(false)

    readonly autocompleteSuggestions$ = new BehaviorSubject<
        Suggestion<string>[]
    >([])

    private subscriptions = new Subscription()

    constructor(
        private wagonNumberPipe: UicWagonNumberPipe,
        public groupManagementService: GroupManagementService,
        private formBuilder: FormBuilder,
        private store: Store<GroupManagementState>
    ) {
        this.readOnly$.next(false)

        this.formatWagonNumber = this.formatWagonNumber.bind(this)

        this.subscriptions.add(
            this.store
                .pipe(select(selectPossibleWagonsForUserLoading))
                .subscribe((isLoading) =>
                    this.possibleWagonsForUserLoading$.next(isLoading)
                )
        )

        this.subscriptions.add(
            combineLatest([
                this.store.pipe(
                    select(selectPossibleWagonsForUser),
                    filter(isTruthy)
                ),
                this.isDefaultGroup$.pipe(startWith(false)),
            ]).subscribe(([possibleWagonsForUser, isDefault]) => {
                if (!isDefault) {
                    this.possibleWagons$.next(possibleWagonsForUser)
                    this.validateAllWagonNumbers()
                    this.updateFields()
                }
            })
        )

        this.subscriptions.add(
            this.alreadySelectedWagonNumbers$.asObservable().subscribe(() => {
                this.selectedWagons$.next([])
                this.invalidWagons$.next([])
                this.numberOfWagons = 0
                this.alreadySelectedWagonNumbers$.value.forEach((wagon) =>
                    this.saveSelectedWagon(wagon)
                )
            })
        )
    }

    ngOnInit() {
        if (this.wagonNumberInputControl) {
            this.subscriptions.add(
                combineLatest([
                    this.possibleWagons$,
                    this.wagonNumberInputControl.valueChanges,
                ]).subscribe(([possibleWagons, _]) => {
                    if (!this.containsMultipleWagonNumbers()) {
                        if (this.minLengthValidator() === null) {
                            if (
                                this.maxLengthValidator() === null &&
                                wagonNumberValidationRegex.test(
                                    this.sanitizedSearchTerm
                                ) &&
                                possibleWagons
                            ) {
                                this.autocompleteSuggestions$.next(
                                    this.filterPossibleWagons(possibleWagons)
                                )
                                this.isWagonNumberInputValid$.next(true)
                            } else {
                                this.isWagonNumberInputValid$.next(false)
                                this.autocompleteSuggestions$.next([])
                            }
                        } else {
                            this.autocompleteSuggestions$.next([])
                            this.isWagonNumberInputValid$.next(true)
                        }
                    } else {
                        this.isWagonNumberInputValid$.next(true)
                        this.autocompleteSuggestions$.next([])
                    }
                })
            )
        }

        this.containsInvalidWagonNumberEntries.emit(
            this.containsInvalidEntries()
        )
    }

    onFocusChange(state: boolean) {
        this.matcher.isControlFocused = state
        if (this.searchMatInputInstance) {
            this.searchMatInputInstance.updateErrorState()
        }
    }

    formatUic(uicClass: string) {
        return formatUicClass(uicClass)
    }

    minLengthValidator(): ValidationErrors | null {
        if (
            this.sanitizedSearchTerm.length <
            this.groupManagementService.minInputLength
        ) {
            return { minLength: true }
        }

        return null
    }

    maxLengthValidator(): ValidationErrors | null {
        if (
            !this.containsMultipleWagonNumbers() &&
            this.sanitizedSearchTerm.length >
                this.groupManagementService.maxInputLength
        ) {
            return { maxLength: true }
        }

        return null
    }

    patternValidator(): ValidatorFn {
        return (): ValidationErrors | null => {
            const value = this.sanitizedSearchTerm
            if (!value.length || this.containsMultipleWagonNumbers()) {
                return null
            }
            return wagonNumberValidationRegex.test(value)
                ? null
                : {
                      pattern: {
                          requiredPattern: wagonNumberValidationRegex,
                          actualValue: value,
                      },
                  }
        }
    }

    //#######################
    // general wagon input
    //#######################

    get searchTerm(): string {
        const value = this.wagonNumberInputControl?.value
        return typeof value === 'string' ? value : value?.value || ''
    }

    get sanitizedSearchTerm() {
        return this.sanitize(this.searchTerm)
    }

    sanitize(value: string) {
        let sanitizedVal = value
        if (sanitizedVal.includes('"')) {
            sanitizedVal = sanitizedVal.replace('"', '')
        }
        return sanitizedVal.replace(/[ -]/g, '')
    }

    formatWagonNumber(suggestion: Suggestion<string>) {
        return this.wagonNumberPipe.transform(suggestion.label)
    }

    // only display wagons that are not already selected for the current group
    filterPossibleWagons(possibleWagons: Wagon[]): Suggestion<string>[] {
        const alreadySelectedWagonIds = this.selectedWagons$.value.map(
            (selectedWagon) => selectedWagon.wagonId
        )
        const res = possibleWagons
            .filter((wagon) => !alreadySelectedWagonIds.includes(wagon.wagonId))
            .filter((wagon) =>
                wagon.wagonNumber.includes(this.sanitizedSearchTerm)
            )

        return res
            .map(
                (wagon) =>
                    ({
                        label: wagon.wagonNumber,
                        value: wagon.wagonNumber,
                    }) as Suggestion<string>
            )
            .sort((first, second) => first.label.localeCompare(second.label))
    }

    getWagonForWagonNumber(wagonNumber: string) {
        return this.possibleWagons$.value.find(
            (wagon) => wagon.wagonNumber === wagonNumber
        )
    }

    selectSuggestion(suggestion: Suggestion<string>) {
        if (this.isWagonNumberInputValid$.value) {
            const selectedWagon = this.getWagonForWagonNumber(suggestion.value)
            if (selectedWagon) {
                this.saveSelectedWagon(selectedWagon)
            }
            this.wagonNumberInputControl?.setValue('')
        }
    }

    pushWagonToStart(wagon: Wagon) {
        const selectedWagons = this.selectedWagons$.value
        selectedWagons.unshift({
            wagonId: wagon.wagonId,
            wagonNumber: wagon.wagonNumber,
            uicClass: wagon.uicClass,
            isLoading: false,
            hasError: false,
            isInEditMode: false,
        })
        this.selectedWagons$.next(selectedWagons)

        this.updateFields()
    }

    saveSelectedWagon(wagon: Wagon) {
        this.selectedWagons$.next(
            this.selectedWagons$.value.concat({
                wagonId: wagon.wagonId,
                wagonNumber: wagon.wagonNumber,
                uicClass: wagon.uicClass,
                isLoading: false,
                hasError: false,
                isInEditMode: false,
            })
        )

        this.updateFields()
    }

    enterWagonNumberEditMode(idx: number) {
        const invalidWagons = this.invalidWagons$.value
        this.wagonNumberEditControl.setValue(invalidWagons[idx].wagonNumber)
        invalidWagons.forEach((wagon) => (wagon.isInEditMode = false))
        invalidWagons[idx].isInEditMode = true
        this.invalidWagons$.next(invalidWagons)
    }

    onKeyDown(event: KeyboardEvent, idx: number) {
        if (event.key === 'Enter') {
            this.editWagonNumber(idx)
        }
    }

    editWagonNumber(idx: number) {
        const invalidWagons = this.invalidWagons$.value
        invalidWagons[idx].isInEditMode = false
        invalidWagons[idx].wagonNumber = this.wagonNumberEditControl.value ?? ''
        this.invalidWagons$.next(invalidWagons)

        this.validatePreviousInvalidWagonNumber(idx)
        this.mergeDoubleWagonNumbers()
        this.updateFields()
    }

    cancelEdit(idx: number) {
        const invalidWagons = this.invalidWagons$.value
        invalidWagons[idx].isInEditMode = false
        this.invalidWagons$.next(invalidWagons)
    }

    mergeDoubleWagonNumbers() {
        let selectedWagons = this.selectedWagons$.value
        const wagonNumbers = selectedWagons.map(
            (selectedWagon) => selectedWagon.wagonNumber
        )
        const duplicateWagonNumbers = wagonNumbers.filter(
            (wagonNumber, index) => wagonNumbers.indexOf(wagonNumber) !== index
        )
        duplicateWagonNumbers.forEach((duplicate) => {
            const indices = selectedWagons
                .map((selectedWagon, index) =>
                    selectedWagon.wagonNumber === duplicate ? index : ''
                )
                .filter(String)
            selectedWagons = selectedWagons.filter((e, i) => i !== indices[1])
        })
        this.selectedWagons$.next(selectedWagons)
    }

    validatePreviousInvalidWagonNumber(index: number) {
        let invalidWagons = this.invalidWagons$.value

        const wagonData = this.getWagonForWagonNumber(
            this.sanitize(invalidWagons[index].wagonNumber)
        )

        if (wagonData !== null && wagonData !== undefined) {
            this.pushWagonToStart(wagonData)

            invalidWagons = invalidWagons.filter(
                (invalidWagon, i) => i != index
            )
        } else {
            invalidWagons[index].hasError = true
            invalidWagons[index].isInEditMode = false
        }

        this.invalidWagons$.next(invalidWagons)
        this.updateFields()
    }

    validateAllWagonNumbers() {
        if (!this.possibleWagons$.value) {
            return
        }

        let selectedWagons = this.selectedWagons$.value
        const invalidWagons: SelectedWagon[] = []

        selectedWagons.forEach((selectedWagon, index) => {
            const wagonData = this.getWagonForWagonNumber(
                this.sanitize(selectedWagon.wagonNumber)
            )

            if (wagonData !== null && wagonData !== undefined) {
                selectedWagons[index].wagonId = wagonData.wagonId
                selectedWagons[index].wagonNumber = wagonData.wagonNumber
                selectedWagons[index].uicClass = wagonData.uicClass
                selectedWagons[index].hasError = false
            } else {
                selectedWagons[index].hasError = true
                invalidWagons.push(selectedWagons[index])
            }
            selectedWagons[index].isLoading = false
        })

        invalidWagons.forEach((invalidWagon) => {
            selectedWagons = selectedWagons.filter(
                (selectedWagon) => selectedWagon.wagonId != invalidWagon.wagonId
            )
        })

        if (invalidWagons.length > 0) {
            this.invalidWagons$.next(invalidWagons)
        }
        this.selectedWagons$.next(selectedWagons)
    }

    deleteValidWagonNumber(idx: number, event: PointerEvent) {
        // to prevent accidental deletion of wagon numbers by pressing enter
        if (event.pointerId === -1) {
            return
        }
        this.selectedWagons$.next(
            this.selectedWagons$.value.filter((e, i) => i !== idx)
        )
        this.updateFields()
    }

    deleteInvalidWagonNumber(idx: number, event: PointerEvent) {
        // to prevent accidental deletion of wagon numbers by pressing enter
        if (event.pointerId === -1) {
            return
        }
        this.invalidWagons$.next(
            this.invalidWagons$.value.filter((e, i) => i !== idx)
        )
        this.updateFields()
    }

    deleteAllInvalidWagonNumbers() {
        this.invalidWagons$.next([])
        this.updateFields()
    }

    invalidWagonsCount() {
        return this.invalidWagons$.value.length
    }

    containsInvalidEntries() {
        return this.invalidWagonsCount() > 0
    }

    updateFields() {
        this.numberOfWagons = this.selectedWagons$.value.length
        this.selectedWagons.emit(this.selectedWagons$.value)
        this.containsInvalidWagonNumberEntries.emit(
            this.containsInvalidEntries()
        )
    }

    //#######################
    // paste multiple wagons
    //#######################

    onPaste(event: ClipboardEvent) {
        const clipBoardData = event.clipboardData?.getData('text')
        this.wagonNumberInputControl.setValue(clipBoardData ?? '')
        if (this.containsMultipleWagonNumbers()) {
            const enteredWagonNumbers = this.processMultiWagonNumberInput()
            this.checkAndSavePastedWagonNumbers(enteredWagonNumbers)

            setTimeout(() => {
                this.wagonNumberInputControl.setValue('')
            }, 0)
        }
    }

    containsMultipleWagonNumbers(): boolean {
        return this.separatorRegex.test(this.sanitizedSearchTerm)
    }

    processMultiWagonNumberInput(): string[] {
        const matches = this.sanitizedSearchTerm.split(this.separatorRegex)
        return matches?.map((match) => this.sanitize(match)) ?? []
    }

    checkAndSavePastedWagonNumbers(wagonNumbers: string[]) {
        this.wagonNumberInputControl.setValue('')
        const uniqueWagonNumbers = [...new Set(wagonNumbers)]
        // add all entered wagon numbers without validation
        uniqueWagonNumbers.forEach((wagonNum) => {
            if (
                this.selectedWagons$.value.findIndex(
                    (selectedWagon) => selectedWagon.wagonNumber === wagonNum
                ) === -1 &&
                this.sanitize(wagonNum) !== ''
            ) {
                this.selectedWagons$.next(
                    this.selectedWagons$.value.concat({
                        wagonId: '',
                        wagonNumber: wagonNum,
                        uicClass: '',
                        isLoading: true,
                        hasError: false,
                        isInEditMode: false,
                    })
                )
            }
        })

        if (
            this.possibleWagons$.value &&
            !this.possibleWagonsForUserLoading$.value
        ) {
            this.validateAllWagonNumbers()
        }
        this.updateFields()
    }

    ngOnDestroy() {
        this.subscriptions.unsubscribe()
    }

    protected readonly InlineMessageType = InlineMessageType
}
