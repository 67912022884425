<div class="flex flex-col pb-6 h-full">
    <div class="flex justify-between items-center">
        <div class="flex pb-4">
            <span translate class="text-body-3 pr-1">Wagons</span>
            <span class="text-body-3" *ngIf="numberOfWagons > 0"
                >({{ numberOfWagons }})</span
            >
        </div>
        <ng-container
            *ngIf="(isAdmin$ | async) === false && (accessPeriodEndOn$ | async)"
        >
            <div class="ml-4 mb-4 flex items-center">
                <mat-icon
                    svgIcon="warning"
                    class="mr-2 text-accent-dark"
                ></mat-icon>
                <translate
                    class="text-accent"
                    [params]="{ endOn: accessPeriodEndOn$ | async }"
                    >This group is visible until $$endOn$$</translate
                >
            </div>
        </ng-container>
    </div>

    @if (isDefaultGroup$ | async) {
        <app-inline-message [inlineMessageType]="inlineMessageType.INFO">
            <div content>
                <translate>AdminAllWagons</translate>
            </div>
        </app-inline-message>
    } @else {
        @if (
            (readOnly$ | async) === false &&
            (isSynchronizedGroup$ | async) === false
        ) {
            <span class="text-accent-medium pb-4 pt-2" translate>
                WagonNumberInputHint
            </span>
            <mat-form-field appearance="outline" class="pb-4">
                <input
                    matInput
                    type="text"
                    [formControl]="wagonNumberInputControl"
                    [matAutocomplete]="autocomplete"
                    [errorStateMatcher]="matcher"
                    (focus)="onFocusChange(true)"
                    (blur)="onFocusChange(false)"
                    (paste)="onPaste($event)"
                />
                <mat-label translate>Enter or paste wagon numbers</mat-label>
                <mat-autocomplete
                    autoActiveFirstOption
                    #autocomplete="matAutocomplete"
                    (optionSelected)="selectSuggestion($event.option.value)"
                    [displayWith]="formatWagonNumber"
                >
                    <mat-option
                        disabled
                        *ngIf="
                            (possibleWagonsForUserLoading$ | async) &&
                                sanitizedSearchTerm.length >=
                                    groupManagementService.minInputLength;
                            else options
                        "
                    >
                        <app-loading-bar></app-loading-bar>
                    </mat-option>
                    <ng-template #options>
                        <mat-option
                            class="suggestion"
                            *ngFor="
                                let option of autocompleteSuggestions$ | async
                            "
                            [value]="option"
                        >
                            {{ option.label | uicWagonNumber }}
                        </mat-option>
                    </ng-template>
                    <mat-option
                        disabled
                        class="empty"
                        *ngIf="
                            (autocompleteSuggestions$ | async)?.length === 0 &&
                            (possibleWagonsForUserLoading$ | async) === false &&
                            containsMultipleWagonNumbers() === false &&
                            sanitizedSearchTerm.length >=
                                groupManagementService.minInputLength &&
                            wagonNumberInputControl?.valid
                        "
                    >
                        <span translate>No matches found</span>
                    </mat-option>
                </mat-autocomplete>

                <mat-hint
                    *ngIf="
                        matcher.isControlFocused &&
                        wagonNumberInputControl?.dirty &&
                        wagonNumberInputControl?.valid
                    "
                >
                    <translate *ngIf="sanitizedSearchTerm.length < 3"
                        >Please enter at least 3 digits</translate
                    >
                </mat-hint>

                <app-button
                    *ngIf="wagonNumberInputControl?.value"
                    matSuffix
                    aria-label="clear search input"
                    (buttonClick)="wagonNumberInputControl?.setValue('')"
                    icon="close"
                    iconButton
                >
                </app-button>

                <mat-error
                    *ngIf="
                        wagonNumberInputControl?.hasError('pattern');
                        else maxLengthError
                    "
                >
                    <translate>
                        Only numbers from 0-9 and - are allowed as inputs.
                    </translate>
                </mat-error>
                <ng-template #maxLengthError>
                    <mat-error
                        *ngIf="wagonNumberInputControl?.hasError('maxLength')"
                    >
                        <translate
                            >A wagon number can't have more than 12
                            digits</translate
                        >
                    </mat-error>
                </ng-template>
            </mat-form-field>
        }

        @if ((isSynchronizedGroup$ | async) === true) {
            <app-inline-message
                [inlineMessageType]="inlineMessageType.INFO"
                class="mb-4"
            >
                <div content>
                    @if (isAdmin$ | async) {
                        <translate
                            >SynchronizedGroupsInlineMessageAdmin</translate
                        >
                    } @else {
                        <translate
                            >SynchronizedGroupsInlineMessageUser</translate
                        >
                    }
                </div>
            </app-inline-message>
        }

        @if (containsInvalidEntries()) {
            <div>
                <app-inline-message
                    [inlineMessageType]="InlineMessageType.WARNING"
                >
                    <span content
                        ><translate
                            *ngIf="invalidWagonsCount() > 1"
                            [params]="{
                                invalidWagonNumberCount:
                                    invalidWagonsCount().toString(),
                            }"
                            >$$invalidWagonNumberCount$$ items can not be added
                            to the list. Please edit or delete.</translate
                        >
                        <translate *ngIf="invalidWagonsCount() === 1"
                            >WagonNumbersInvalidSingleItemHint</translate
                        ></span
                    >
                    <app-button
                        button
                        buttonType="basic-danger"
                        (buttonClick)="deleteAllInvalidWagonNumbers()"
                        ><translate>Delete invalid items</translate>
                    </app-button>
                </app-inline-message>

                <div class="pb-3 pt-3">
                    <div
                        *ngFor="
                            let wagonEntry of invalidWagons$ | async;
                            let wagonNumberIndex = index
                        "
                        class="wagon-number-entry error-entry"
                        [ngClass]="{
                            'edit-entry': wagonEntry.isInEditMode,
                        }"
                    >
                        <div class="flex h-[40px] items-center justify-between">
                            <div class="flex">
                                <div class="pr-8 min-w-[140px]">
                                    <div class="flex items-center pl-4">
                                        <mat-icon
                                            *ngIf="
                                                wagonEntry.isInEditMode ===
                                                false
                                            "
                                            svgIcon="warning"
                                            class="warning-icon"
                                            [matTooltip]="
                                                'WagonNumberInvalid' | translate
                                            "
                                            matTooltipClass="tooltip"
                                        ></mat-icon>
                                        <span
                                            *ngIf="
                                                wagonEntry.isInEditMode ===
                                                false
                                            "
                                            class="error-wagon-number"
                                        >
                                            {{
                                                wagonEntry.wagonNumber
                                                    | uicWagonNumber
                                            }}
                                        </span>
                                        <mat-form-field
                                            appearance="fill"
                                            class="full-width"
                                            *ngIf="wagonEntry.isInEditMode"
                                        >
                                            <input
                                                autofocus
                                                matInput
                                                type="text"
                                                class="min-w-[250px]"
                                                [formControl]="
                                                    wagonNumberEditControl
                                                "
                                                (keydown)="
                                                    onKeyDown(
                                                        $event,
                                                        wagonNumberIndex
                                                    )
                                                "
                                            />
                                        </mat-form-field>
                                    </div>
                                </div>
                            </div>
                            <div class="justify-self-end">
                                <app-button-group
                                    *ngIf="wagonEntry.isInEditMode === false"
                                    alignment="right"
                                >
                                    <app-button
                                        buttonType="ghost"
                                        iconButton
                                        icon="edit"
                                        (click)="
                                            enterWagonNumberEditMode(
                                                wagonNumberIndex
                                            )
                                        "
                                        class="mr-0"
                                    ></app-button>
                                    <app-button
                                        *ngIf="
                                            (readOnly$ | async) === false &&
                                            (isSynchronizedGroup$ | async) ===
                                                false
                                        "
                                        buttonType="ghost"
                                        iconButton
                                        class="pr-2 ml-0"
                                        icon="delete-outline"
                                        (buttonClick)="
                                            deleteInvalidWagonNumber(
                                                wagonNumberIndex,
                                                $event
                                            )
                                        "
                                    ></app-button>
                                </app-button-group>
                                <app-button-group
                                    *ngIf="wagonEntry.isInEditMode"
                                    alignment="right"
                                >
                                    <app-button
                                        iconButton
                                        icon="check"
                                        (click)="
                                            editWagonNumber(wagonNumberIndex)
                                        "
                                        class="mr-0"
                                    ></app-button>
                                    <app-button
                                        iconButton
                                        class="pr-2 ml-0"
                                        icon="close"
                                        (buttonClick)="
                                            cancelEdit(wagonNumberIndex)
                                        "
                                    ></app-button>
                                </app-button-group>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        }

        <div>
            <div
                *ngFor="
                    let wagonEntry of selectedWagons$ | async;
                    let wagonNumberIndex = index
                "
                class="wagon-number-entry"
                [ngClass]="{
                    'loading-entry': wagonEntry.isLoading,
                }"
            >
                <div class="flex h-[40px] items-center justify-between">
                    <div class="flex">
                        <div class="pr-8 min-w-[140px]">
                            <div
                                *ngIf="wagonEntry.isLoading"
                                class="flex items-center pl-4"
                            >
                                <mat-icon
                                    svgIcon="warning"
                                    class="warning-icon"
                                    matTooltipClass="tooltip"
                                ></mat-icon>
                                <span class="error-wagon-number">
                                    {{
                                        wagonEntry.wagonNumber | uicWagonNumber
                                    }}
                                </span>
                            </div>
                            <a
                                *ngIf="wagonEntry.isLoading === false"
                                target="_blank"
                                rel="opener"
                                [routerLink]="['/wagon', wagonEntry.wagonId]"
                                trackClick
                                [trackCategory]="
                                    AnalyticsCategory.GroupManagement
                                "
                                [trackAction]="AnalyticsGroupManagementAction"
                                class="text-body-3 cursor-pointer pl-4"
                            >
                                {{ wagonEntry.wagonNumber | uicWagonNumber }}
                            </a>
                        </div>
                        <span class="text-accent-medium">{{
                            formatUic(wagonEntry.uicClass)
                        }}</span>
                    </div>
                    <div class="justify-self-end">
                        <app-loading-indicator
                            *ngIf="wagonEntry.isLoading"
                            small
                            class="text-accent-medium pr-4"
                        ></app-loading-indicator>
                        <app-button-group
                            *ngIf="wagonEntry.isLoading === false"
                            alignment="right"
                        >
                            <app-button
                                *ngIf="
                                    (readOnly$ | async) === false &&
                                    (isSynchronizedGroup$ | async) === false
                                "
                                buttonType="ghost"
                                iconButton
                                class="pr-2 ml-0"
                                icon="delete-outline"
                                (buttonClick)="
                                    deleteValidWagonNumber(
                                        wagonNumberIndex,
                                        $event
                                    )
                                "
                            ></app-button>
                        </app-button-group>
                    </div>
                </div>
            </div>
        </div>
    }
</div>
