import { Stage } from '@env-lib/stage'
import { ApiConfigModel } from './api-config.model'

const baseUrl = 'https://int.traigo.com'
const authDomain = 'https://traigo-int.auth.eu-central-1.amazoncognito.com'
const clientId = '77ckel4a1gceug83md78rigs52'
const cognitoPoolId = 'eu-central-1_wDmVmw61Q'
const adLogoutUrl = `https://vtgb2c.b2clogin.com/vtgb2c.onmicrosoft.com/B2C_1_Traigo_SignIn_Int/oauth2/v2.0/logout?post_logout_redirect_uri=${baseUrl}/logout`

export const apiConfig: ApiConfigModel = {
    account: {
        backendUrl: 'https://auth.int.traigo.com',
    },
    analytics: {
        googleTagManagerId: 'GTM-N4878PS',
        googleTagManagerEnvironment: 'env-9',
        googleTagManagerAuth: 'vrvkoUrmytXaypNPodNmYw',
    },
    assets: { backendUrl: 'https://assets.int.traigo.com' },
    auth: {
        backendUrl: 'https://auth.int.traigo.com',
        clientId,
        cognitoLogoutUrl: `${authDomain}/logout?client_id=${clientId}&logout_uri=${adLogoutUrl}`,
        issuer: `https://cognito-idp.eu-central-1.amazonaws.com/${cognitoPoolId}`,
        loginUrl: `${authDomain}/oauth2/authorize`,
        redirectUrl: `${baseUrl}/auth`,
        tokenEndpoint: `${authDomain}/oauth2/token`,
    },
    connectEvent: {
        backendUrl: 'https://connect-event.int.traigo.com',
    },
    contracts: {
        backendUrl: 'https://contracts.int.traigo.com',
    },
    dashboard: {
        backendUrl: 'https://dashboard.int.traigo.com',
    },
    dataForwarding: {
        backendUrl: 'https://message-relay.int.traigo.com',
    },
    dispatch: {
        backendUrl: 'https://dispatch.int.traigo.com',
    },
    fastTrack: {
        backendUrl: 'https://fasttrack.int.traigo.com',
    },
    fleet: {
        backendUrl: 'https://fleet.int.traigo.com',
    },
    mixpanel: {
        token: 'e3de8ea8b84524e85b045c0d4bb01166',
    },
    performance: {
        backendUrl: 'https://performance.int.traigo.com',
    },
    salesCare: {
        backendUrl: 'https://sales-care.int.traigo.com',
    },
    launchDarkly: {
        clientSideId: '5e5e20f30cd67409b07700b0',
        useStreaming: true,
    },
    releasenotes: {
        backendUrl: 'https://releasenotes.int.traigo.com',
    },
    datadog: {
        environmentName: Stage.Int,
    },
    transport: {
        backendUrl: 'https://transport.int.traigo.com',
    },
    e2eEta: {
        backendUrl: 'https://e2e-eta.int.traigo.com',
    },
    userInterest: {
        backendUrl: 'https://user-interest.int.traigo.com',
    },
    wagon: {
        backendUrl: 'https://wagon.int.traigo.com',
    },
    pathfinder: {
        backendUrl: 'https://pathfinder.int.traigo.com',
    },
    virtuwall: {
        backendUrl: 'https://virtuwall.int.traigo.com',
    },
    wagonHire: {
        backendUrl: 'https://wagon-rental.int.traigo.com',
    },
    notification: {
        backendUrl: 'https://notification.int.traigo.com',
    },
    sensor: {
        backendUrl: 'https://sensor.int.traigo.com',
    },
}
