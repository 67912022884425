import { ErrorType } from '@error-util-lib/error.model'
import {
    Colleague,
    GroupMetaData,
    Groups,
    GroupSource,
    Wagon,
} from '@group-management-lib/group-management.model'

export const groupManagementFeatureKey = 'groupManagement'

export type GroupManagementStore = Readonly<{
    [groupManagementFeatureKey]: GroupManagementState
}>

export type GroupManagementState = Readonly<{
    groupsFromBackendLoading: boolean
    groupsFromBackendError: ErrorType | null
    groupsWithResourcesLoading: boolean
    groupsWithResourcesError: ErrorType | null
    groupsWithResources: Groups[] | null
    hasSynchronizedGroups: boolean
    groupsFromToken: string[] | null
    groupsFromBackend: GroupMetaData[] | null
    groupIdToEdit: string | null
    wagonsForSelectedGroup: Wagon[] | null
    wagonsForSelectedGroupLoading: boolean
    wagonsForSelectedGroupError: ErrorType | null
    changeSelectedWagonsForGroupLoading: boolean
    changeSelectedWagonsForGroupError: ErrorType | null
    changeMembersOfGroupLoading: boolean
    changeMembersOfGroupError: ErrorType | null
    possibleWagonsForUser: Wagon[] | null
    possibleWagonsForUserLoading: boolean
    possibleWagonsForUserError: ErrorType | null
    checkGroupsEqual: boolean | null
    lastGroupSource: GroupSource | null
    refreshBackend: boolean
    changeGroupSelectionLoading: boolean
    changeGroupSelectionError: ErrorType | null
    createGroupLoading: boolean
    createGroupError: ErrorType | null
    editGroupLoading: boolean
    editGroupError: ErrorType | null
    deleteGroupLoading: boolean
    deleteGroupError: ErrorType | null
    colleagues: Colleague[]
}>

export const initialGroupManagementState: GroupManagementState = {
    groupsFromBackendLoading: false,
    groupsFromBackendError: null,
    groupsFromBackend: null,
    groupsWithResourcesLoading: false,
    groupsWithResourcesError: null,
    groupsWithResources: null,
    hasSynchronizedGroups: false,
    groupsFromToken: null,
    groupIdToEdit: null,
    wagonsForSelectedGroup: null,
    wagonsForSelectedGroupLoading: false,
    wagonsForSelectedGroupError: null,
    changeSelectedWagonsForGroupLoading: false,
    changeSelectedWagonsForGroupError: null,
    changeMembersOfGroupLoading: false,
    changeMembersOfGroupError: null,
    possibleWagonsForUser: null,
    possibleWagonsForUserLoading: false,
    possibleWagonsForUserError: null,
    checkGroupsEqual: null,
    lastGroupSource: null,
    refreshBackend: false,
    changeGroupSelectionLoading: false,
    changeGroupSelectionError: null,
    createGroupLoading: false,
    createGroupError: null,
    editGroupLoading: false,
    editGroupError: null,
    deleteGroupLoading: false,
    deleteGroupError: null,
    colleagues: [],
}
