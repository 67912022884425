import { Injectable } from '@angular/core'
import { EnvService } from '../env.service'
import { isDevelopment } from '../isDevelopment'
import { isTestCafeTest } from '../isTestCafeTest'
import { Stage } from '../stage'
import { apiConfig as devConfig } from './api-config.dev'
import { apiConfig as intConfig } from './api-config.int'
import { apiConfig as localDevConfig } from './api-config.local-dev'
import { apiConfig as localIntConfig } from './api-config.local-int'
import { ApiConfigModel } from './api-config.model'
import { apiConfig as prodConfig } from './api-config.prod'
import { apiConfig as testCafeConfig } from './api-config.testcafe'

const ApiConfigs = {
    Dev: devConfig,
    Int: intConfig,
    Prod: prodConfig,
    TestCafe: testCafeConfig,
    LocalDev: localDevConfig,
    LocalInt: localIntConfig,
}

@Injectable({
    providedIn: 'root',
})
export class ApiService implements ApiConfigModel {
    private _apiConfig?: ApiConfigModel

    account!: ApiConfigModel['account']
    analytics!: ApiConfigModel['analytics']
    auth!: ApiConfigModel['auth']
    connectEvent!: ApiConfigModel['connectEvent']
    contracts!: ApiConfigModel['contracts']
    dashboard!: ApiConfigModel['dashboard']
    dataForwarding!: ApiConfigModel['dataForwarding']
    datadog!: ApiConfigModel['datadog']
    dispatch!: ApiConfigModel['dispatch']
    fastTrack!: ApiConfigModel['fastTrack']
    fleet!: ApiConfigModel['fleet']
    launchDarkly!: ApiConfigModel['launchDarkly']
    mixpanel!: ApiConfigModel['mixpanel'] | undefined
    performance!: ApiConfigModel['performance']
    releasenotes!: ApiConfigModel['releasenotes']
    salesCare!: ApiConfigModel['salesCare']
    transport!: ApiConfigModel['transport']
    e2eEta!: ApiConfigModel['e2eEta']
    userInterest!: ApiConfigModel['userInterest']
    wagon!: ApiConfigModel['wagon']
    pathfinder!: ApiConfigModel['pathfinder']
    virtuwall!: ApiConfigModel['virtuwall']
    wagonHire!: ApiConfigModel['wagonHire']
    notification!: ApiConfigModel['notification']
    sensor!: ApiConfigModel['sensor']
    assets!: ApiConfigModel['assets']

    constructor(private envService: EnvService) {
        this._apiConfig = this.getApiConfig()
        this.makeApiConfigAvailable()
    }

    private getApiConfig() {
        switch (this.envService.stage) {
            case Stage.Dev:
                return isDevelopment() ? ApiConfigs.LocalDev : ApiConfigs.Dev

            case Stage.Int: {
                if (isTestCafeTest()) {
                    return ApiConfigs.TestCafe
                }

                if (isDevelopment()) {
                    return ApiConfigs.LocalInt
                }

                return ApiConfigs.Int
            }

            case Stage.Prod:
            default:
                return ApiConfigs.Prod
        }
    }

    private makeApiConfigAvailable() {
        if (!this._apiConfig) {
            return
        }

        for (const key in this._apiConfig) {
            if (typeof key === 'string') {
                Object.defineProperty(this, key, {
                    get: () =>
                        this._apiConfig &&
                        this._apiConfig[key as keyof ApiConfigModel],
                    set: () => {
                        throw new Error('Cannot overwrite api config')
                    },
                })
            }
        }
    }
}
